import React from "react";
import { Route, Switch } from "react-router-dom";
import Template from "../template";

import WelcomePage from "../pages/welcome";
import AnalyticsRoutes from "../routes/AnalyticsRoutes";
import WayOfDoing from "../pages/wayOfDoing";
import WayOfDoingEdit from "../pages/wayOfDoing/edit";
import WayOfDelivering from "../pages/wayOfDelivering";
import WayOfDeliveringEdit from "../pages/wayOfDelivering/edit";
import FeatureGroup from "../pages/featureGroup";
import FeatureGroupEdit from "../pages/featureGroup/edit";
import WayOfPayment from "../pages/wayOfPayment";
import WayOfPaymentEdit from "../pages/wayOfPayment/edit";
import Environment from "../pages/environment";
import EnvironmentEdit from "../pages/environment/edit";
import ClassificationStructure from "../pages/classificationStructure";
import ClassificationStructureEdit from "../pages/classificationStructure/edit";
import ComplementaryFeatures from "../pages/complementaryFeatures";
import ComplementaryFeaturesEdit from "../pages/complementaryFeatures/edit";
import RelatedClassification from "../pages/complementaryFeatures/RelatedClassification/index";
import RelatedPromotionClassification from "../pages/environmentPromotions/RelatedClassification/index";
import RelatedAdClassification from "../pages/announceType/RelatedAnnounceType/index";
import typeOfStore from "../pages/typeOfStore";
import typeOfStoreEdit from "../pages/typeOfStore/edit";
import storeManagement from "../pages/storeManagement";
import store from "../pages/store";
import storeEdit from "../pages/store/edit";
import announce from "../pages/announce";
import looseAnnounce from "../pages/loose-announce";
import announceType from "../pages/announceType";
import announceTypeEdit from "../pages/announceType/edit";
import RegistrationPage from "../pages/registration";
import RegistrationEditPage from "../pages/registration/edit";
import OrderManagement from "../pages/OrderManagement";
import OrderManagementDetails from "../pages/OrderManagement/details";
import EnvironmentPromotions from "../pages/environmentPromotions";
import EnvironmentPromotionsEdit from "../pages/environmentPromotions/edit";
import Banners from "../pages/banners/";
import BannersEdit from "../pages/banners/edit";
import RelatedBannerClassification from "../pages/banners/RelatedBannerClassification/index";
import RelatedBannerEnvironment from "../pages/banners/RelatedBannerEnvironment/index";

const PrivateRoutes = ({ auth }) => {
  return (
    <Switch>
      <Route exact path="/">
        <Template component={WelcomePage} auth={auth} />
      </Route>
      <Route exact path="/order-management">
        <Template component={OrderManagement} />
      </Route>
      <Route exact path="/order-management/details/:orderId?">
        <Template component={OrderManagementDetails} />
      </Route>
      <Route exact path="/registration">
        <Template component={RegistrationPage} />
      </Route>
      <Route exact path="/registration/:action/:profileId?">
        <Template component={RegistrationEditPage} />
      </Route>
      <Route exact path="/way-of-doing">
        <Template component={WayOfDoing} />
      </Route>
      <Route exact path="/way-of-doing/:action/:wayId?">
        <Template component={WayOfDoingEdit} />
      </Route>

      <Route exact path="/way-of-delivering">
        <Template component={WayOfDelivering} />
      </Route>
      <Route exact path="/way-of-delivering/:action/:wayId?">
        <Template component={WayOfDeliveringEdit} />
      </Route>

      <Route exact path="/feature-group">
        <Template component={FeatureGroup} />
      </Route>
      <Route exact path="/feature-group/:action/:groupId?">
        <Template component={FeatureGroupEdit} />
      </Route>

      <Route exact path="/way-of-payment">
        <Template component={WayOfPayment} />
      </Route>
      <Route exact path="/way-of-payment/:action/:wayId?">
        <Template component={WayOfPaymentEdit} />
      </Route>

      <Route exact path="/environment">
        <Template component={Environment} />
      </Route>
      <Route exact path="/environment/:action/:environmentId?">
        <Template component={EnvironmentEdit} />
      </Route>

      <Route exact path="/environment-promotions">
        <Template component={EnvironmentPromotions} />
      </Route>
      <Route exact path="/environment-promotions/:action/:promotionId?">
        <Template component={EnvironmentPromotionsEdit} />
      </Route>

      <Route exact path="/classification-structure">
        <Template component={ClassificationStructure} />
      </Route>
      <Route exact path="/classification-structure/:action/:structureId?">
        <Template component={ClassificationStructureEdit} />
      </Route>

      <Route exact path="/complementary-features">
        <Template component={ComplementaryFeatures} />
      </Route>
      <Route exact path="/complementary-features/:action/:featureId?">
        <Template component={ComplementaryFeaturesEdit} />
      </Route>

      <Route exact path="/type-store">
        <Template component={typeOfStore} />
      </Route>
      <Route exact path="/type-store/:action/:typeId?">
        <Template component={typeOfStoreEdit} />
      </Route>

      <Route exact path="/store-management">
        <Template component={storeManagement} />
      </Route>

      <Route exact path="/store-emphasis">
        <Template component={store} />
      </Route>
      <Route exact path="/store/:action/:storeId?">
        <Template component={storeEdit} />
      </Route>

      <Route exact path="/announce-emphasis">
        <Template component={announce} />
      </Route>

      <Route exact path="/loose-announce-emphasis">
        <Template component={looseAnnounce} />
      </Route>

      <Route exact path="/announce-type">
        <Template component={announceType} />
      </Route>
      <Route exact path="/announce-type/:action/:typeId?">
        <Template component={announceTypeEdit} />
      </Route>

      <Route exact path="/related-classification/:complementaryFeatureId">
        <Template component={RelatedClassification} />
      </Route>
      <Route exact path="/related-promotion-classification/:promotionId">
        <Template component={RelatedPromotionClassification} />
      </Route>
      <Route exact path="/related-announce-classification/:announceTypeId">
        <Template component={RelatedAdClassification} />
      </Route>

      <Route exact path="/banners">
        <Template component={Banners} />
      </Route>
      <Route exact path="/banners/:action/:bannerId?">
        <Template component={BannersEdit} />
      </Route>
      <Route exact path="/related-banner-classification/:bannerId">
        <Template component={RelatedBannerClassification} />
      </Route>
      <Route exact path="/related-banner-environment/:bannerId">
        <Template component={RelatedBannerEnvironment} />
      </Route>

      <AnalyticsRoutes />

      {/* Not found page */}
      <Route path="*">
        <Template />
      </Route>
    </Switch>
  );
};

export default PrivateRoutes;
