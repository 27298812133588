import React from "react";
import { FormArea } from "../styled";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import API from "../../../utils/api";

const AssociationTable = ({ action }) => {
  const { bannerId } = useParams();
  const [environments, setEnvironments] = useState([]);
  const [environmentId, setEnvironmentId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await API.getEnvironmentActiveAndFilter();
      setEnvironments(response.data.body.data);
      return response;
    }
    fetchData();
  }, []);

  const validateForm = () => {
    if (!environmentId) return false;
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      bannerId: bannerId,
      environmentId: environmentId,
    };

    try {
      const response = await API.createBannerEnvironment(requestBody);

      if (response.data.statusCode === 200) {
        await swal({
          title: "Tudo certo!",
          text: "Banner foi associado com sucesso!",
          icon: "success",
        });
        action("view");
        return;
      }

      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="type">Associar um Ambiente:</label>
          <select
            id="type"
            onChange={(e) => {
              setEnvironmentId(e.target.value);
            }}
          >
            <option value={null}>Selecione um ambiente</option>
            {environments.map((classification) => {
              return (
                <option key={classification._id} value={classification._id}>
                  {classification.description}
                </option>
              );
            })}
          </select>

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};

export default AssociationTable;
