import React from "react";

import { PageArea } from "./styled";

import Component from "../components/ActiveDeleted";

import SearchFilter from "./SearchFilter";
import ActiveTable from "./ActiveTable";
import DeletedTable from "./DeletedTable";

const Page = () => {
  return (
    <PageArea>
      <div className="page--area">
        <Component
          displayTabs={false}
          searchFilter={SearchFilter}
          activeTable={ActiveTable}
          deletedTable={DeletedTable}
          paginate={true}
          route="/banners"
        />
      </div>
    </PageArea>
  );
};

export default Page;
