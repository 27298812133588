import React, { useState, useEffect } from "react";
import API from "../../utils/api";

import { SearchFilterArea } from "./styled";

let timer;
let delay = 0;
const DELAY_TIME = 800;

const SearchFilter = ({ setSearchMethod, activeDeleteOrRelease }) => {
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    delay = 0;
  }, [activeDeleteOrRelease]);

  useEffect(() => {
    const info = {
      description,
      city,
      status: 1,
    };

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchMethod(() => async () => {
        let response = "";
        if (activeDeleteOrRelease === "active") {
          info.status = 2;
          response = await API.getEnvironmentActiveAndFilter(info);
        } else if (activeDeleteOrRelease === "release") {
          info.status = 1;
          response = await API.getEnvironmentOnReleaseAndFilter(info);
        } else {
          info.status = 3;
          response = await API.getEnvironmentInactiveAndFilter(info);
        }

        const data = response.data.body.data;
        return data;
      });
    }, delay);

    delay = DELAY_TIME;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, city, activeDeleteOrRelease]);

  return (
    <SearchFilterArea>
      Filtrar:
      <div className="search-filter">
        <div className="search-filter__input">
          <input
            type="text"
            placeholder="Pesquisar por descrição"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="search-filter__input">
          <input
            type="text"
            placeholder="Pesquisar por cidade"
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
      </div>
    </SearchFilterArea>
  );
};

export default SearchFilter;
