import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import InputMask from "react-input-mask";
import LengthCounter from "../components/LengthCounter";
import { formatDate, formatTime } from "../../utils/date";

const Edit = () => {
  const history = useHistory();
  const { action, promotionId } = useParams();

  const [environment, setEnvironment] = useState("");
  const [environmentList, setEnvironmentList] = useState([]);

  const [minutesStartTime, setMinutesStartTime] = useState("");
  const [hourStartTime, setHourStartTime] = useState("");

  const [minutesEndTime, setMinutesEndTime] = useState("");
  const [hourEndTime, setHourEndTime] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [description, setDescription] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [orderLimit, setOrderLimit] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [errors, setErrors] = useState({
    description: "",
    orderLimit: "",
    discountValue: "",
    discountType: "",
    deviceType: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, discountType: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountType]);

  useEffect(() => {
    setErrors({ ...errors, deviceType: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountType]);

  useEffect(() => {
    async function fetchData() {
      const response = await API.getEnvironmentActiveAndFilter();
      setEnvironmentList(response.data.body.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !promotionId) ||
      (action === "create" && promotionId)
    ) {
      history.push("/environment-promotions");
      return null;
    }

    if (action === "edit") {
      getEnvironmentPromotions(promotionId);
    }
  }, [action, promotionId, history]);

  const getEnvironmentPromotions = async (id) => {
    try {
      const response = await Api.getEnvironmentPromotionsById(id);
      if (response.status === 200) {
        const {
          description,
          orderLimit,
          discountValue,
          discountType,
          deviceType,
          environmentId,
          startTime,
          endTime,
        } = response.data.body.data;
        setEnvironment(environmentId);

        setHourStartTime(formatDate(startTime));
        setMinutesStartTime(formatTime(startTime));

        setMinutesEndTime(formatTime(endTime));
        setHourEndTime(formatDate(endTime));

        // Convertendo valor do desconto
        const numericDiscountValue = discountValue / 100;
        const numericDiscount =
          numericDiscountValue >= 10
            ? numericDiscountValue.toFixed(2)
            : "0" + numericDiscountValue.toFixed(2);

        setDiscountValue(numericDiscount);

        setOrderLimit(orderLimit);
        setDescription(description);
        setDiscountType(discountType);
        setDeviceType(deviceType);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";
    if (!discountType) err.type = "O campo é obrigatório";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    let total;
    let subst;
    if (discountValue.toString().includes("R")) {
      subst = discountValue.replace("R$ ", "").replace(",", ".").trim();
      total = Number(subst) * 100;
    } else {
      total = Number(discountValue) * 100;
    }

    const requestBody = {
      environmentId: environment,
      description: description,
      startTime: startTime,
      endTime: endTime,
      discountValue: total,
      orderLimit: Number.parseInt(orderLimit),
      discountType: Number.parseInt(discountType),
      deviceType: Number.parseInt(deviceType),
    };
    console.log(requestBody.deviceType);
    try {
      if (action === "edit") {
        await API.updateEnvironmentPromotions(promotionId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Promoção foi atualizada com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createEnvironmentPromotions(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Promoção foi criada com sucesso!",
          icon: "success",
        });
      }

      history.push("/environment-promotions");
    } catch (error) {
      if (
        error.response.data.body.errors ===
        "exceptions:START_TIME_CANNOT_BE_IN_THE_PAST"
      ) {
        await swal({
          title: "Data de inicio não pode ser no passado!",
          text: "Por favor tente outra data.",
          icon: "error",
        });
      } else if (
        error.response.data.body.errors ===
        "exceptions:START_TIME_CANNOT_BE_AFTER_END_TIME"
      ) {
        await swal({
          title: "Data de início não pode ser superior a data de término!",
          text: "Por favor tente outra data.",
          icon: "error",
        });
      } else if (
        error.response.data.body.errors ===
        "exceptions:END_TIME_CANNOT_BE_BEFORE_START_TIME"
      ) {
        await swal({
          title: "Data de término não pode ser inferior a data de início!",
          text: "Por favor tente outra data.",
          icon: "error",
        });
      } else if (
        error.response.data.body.errors ===
        "exceptions:A_PROMOTION_ALREADY_EXISTS_FOR_ENVIRONMENT_AND_PERIOD"
      ) {
        await swal({
          title:
            "Já existe um tipo de promoção registrado para o ambiente na plataforma com nesse periodo de tempo!",
          // text: "Por favor tente outra data ou outra comunidade.",
          icon: "error",
        });
      } else {
        await swal({
          title: "Ocorreu algo de errado!",
          text: "Verifique as informações! Ex: tente outra data.",
          icon: "error",
        });
      }
    }
  };

  const handleConvertValues = async (
    hourStart,
    minutesStart,
    hourEnd,
    minutesEnd
  ) => {
    const startTime = `${hourStart}T${minutesStart}:00`;
    setStartTime(new Date(startTime).toJSON().toLocaleString("pt-Br"));

    const endTime = `${hourEnd}T${minutesEnd}:00`;
    setEndTime(new Date(endTime).toJSON().toLocaleString("pt-Br"));
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/environment-promotions">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Promoção a uma comunidade"
              : "Adicionar Promoção a uma comunidade"}
          </h1>
        </div>

        <form onSubmit={handleFormSubmit}>
          <label htmlFor="community">Selecione uma Comunidade: *</label>
          <select
            id="community"
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
            required={true}
          >
            <option value="" defaultValue>
              Comunidade
            </option>
            {environmentList.map((e) => (
              <option key={e._id} value={e._id}>
                {e.description}
              </option>
            ))}
          </select>
          <label htmlFor="type">Tipo de promoção: *</label>
          <select
            id="type"
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            required={true}
          >
            <option value="" required={true} defaultValue>
              Selecione um tipo
            </option>
            <option value={1}>Primeira Compra</option>
          </select>
          {errors.deviceType !== "" && (
            <div className="form--error">{errors.deviceType}</div>
          )}

          <label htmlFor="description">Descrição: *</label>
          <input
            type="text"
            id="description"
            value={description}
            maxLength={50}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
          />

          <LengthCounter
            props={{
              field: description,
              maxLength: 50,
            }}
          />

          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          <label htmlFor="discount-value">Valor do Desconto: (Reais)*</label>
          <InputMask
            mask="R$ 99,99"
            type="text"
            placeholder="15,00"
            value={discountValue}
            onChange={(e) => {
              setDiscountValue(e.target.value);
            }}
            required={true}
          />

          {errors.discountValue !== "" && (
            <div className="form--error">{errors.discountValue}</div>
          )}
          <label htmlFor="orderLimit">Limite de Pedidos: *</label>
          <input
            type="text"
            id="orderLimit"
            value={orderLimit}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            min={0}
            maxLength={6}
            onChange={(e) => {
              setOrderLimit(e.target.value);
            }}
            required={true}
          />

          <label htmlFor="type">Tipo de dispositivo: *</label>
          <select
            id="type"
            value={deviceType}
            onChange={(e) => setDeviceType(e.target.value)}
            required={true}
          >
            <option value="" required={true} defaultValue>
              Selecione um tipo
            </option>
            <option value={1}>Todos</option>
            <option value={2}>Android</option>
            <option value={3}>IOS</option>
          </select>
          {errors.deviceType !== "" && (
            <div className="form--error">{errors.deviceType}</div>
          )}

          {errors.orderLimit !== "" && (
            <div className="form--error">{errors.orderLimit}</div>
          )}

          <div className="dates">
            <label htmlFor="start-date">Data de Início: *</label>
            <div className="div-date">
              <InputMask
                mask="99:99"
                type="text"
                placeholder="07:30"
                className="input-date"
                value={minutesStartTime}
                onChange={(e) => {
                  setMinutesStartTime(e.target.value);
                }}
                required={true}
              />

              <input
                id="start-date"
                type="date"
                min={new Date()}
                className="input-date"
                value={hourStartTime}
                onChange={(e) => {
                  setHourStartTime(e.target.value);
                }}
                required={true}
              />
            </div>

            <label htmlFor="end-date">Data do Encerramento: *</label>
            <div className="div-date">
              <InputMask
                mask="99:99"
                type="text"
                placeholder="07:30"
                className="input-date"
                value={minutesEndTime}
                onChange={(e) => {
                  setMinutesEndTime(e.target.value);
                }}
                required={true}
              />
              <input
                type="date"
                id="end-date"
                className="input-date"
                value={hourEndTime}
                onChange={(e) => {
                  setHourEndTime(e.target.value);
                }}
                required={true}
              />
            </div>
          </div>
          <button
            onClick={() => {
              handleConvertValues(
                hourStartTime,
                minutesStartTime,
                hourEndTime,
                minutesEndTime
              );
            }}
            type="submit"
          >
            Salvar
          </button>
        </form>
      </div>
    </FormArea>
  );
};

export default Edit;
