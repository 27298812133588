import React from "react";
import { useHistory } from "react-router";
import { formatDateAndTime } from "../../utils/date";
import swal from "sweetalert";
import API from "../../utils/api";
// import { Image } from "./styled";

const ActiveTable = ({ data, refreshTable }) => {
  const history = useHistory();

  const handleEditAction = (bannerId) => {
    history.push(`banners/edit/${bannerId}`);
  };

  const handleManageClassificationAction = (id) => {
    history.push(`/related-banner-classification/${id}`);
  };
  const handleManageEnvironmentAction = (id) => {
    history.push(`/related-banner-environment/${id}`);
  };

  const handleInactivateItem = async (id) => {
    if (await confirmModal()) {
      try {
        const response = await API.deleteBannerById(id);

        if (response.status === 200) {
          await swal({
            title: "Tudo certo!",
            text: "Banner foi excluido com sucesso!",
            icon: "success",
          });

          refreshTable();
        } else {
          await swal({
            title: "Ocorreu algo de errado!",
            text: "Ocorreu algum erro ao excluir o Banner",
            icon: "error",
          });
        }
      } catch (error) {
        if (error.response.data.body.errors === "exceptions:BANNER_IS_LINKED") {
          await swal({
            title: "Banner possui vinculos!",
            text: "Remova o vinculo de banner com classificação ou ambiente.",
            icon: "error",
          });
          return;
        }
        if (!error.response) {
          await swal({
            title: "Algo inesperado aconteceu!",
            text: "Por favor tente novamente mais tarde.",
            icon: "error",
          });
          return;
        }
        await swal({
          title: "Ocorreu algo de errado!",
          text: error.response.data.message,
          icon: "error",
        });
      }
    }
    return;
  };

  const confirmModal = () => {
    const confirm = swal("Tem certeza que deseja excluir esse Banner?", {
      buttons: ["Cancelar", "Confirmar"],
    }).then((value) => (value ? true : false));

    return confirm;
  };

  const TYPE = {
    1: "Estático",
    2: "Animado",
    3: "Video",
    4: "PopUp",
  };

  const ORIGIN = {
    1: "Plataforma",
    2: "Loja",
  };
  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Data de Registro</th>
            <th>Título</th>
            <th>Resumo</th>
            <th>Tipo</th>
            <th>Origem</th>
            <th>Loja</th>
            {/* <th>Imagem</th> */}
            <th>Associações</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDateAndTime(item.createdAt)}</td>
                <td>{item.title}</td>
                <td>{item.resume}</td>
                <td>{TYPE[item.type]}</td>
                <td>{ORIGIN[item.origin]}</td>
                <td>{item.storeId?.title || "---"}</td>
                {/* <td>
                  <Image>
                    <img src={item.imageUrl} alt={item.title + " " + index} />
                  </Image>
                </td> */}
                <td>
                  <button
                    className="manage--button"
                    onClick={() => {
                      handleManageClassificationAction(item._id);
                    }}
                  >
                    Classificação
                  </button>
                  <button
                    className="manage--button"
                    onClick={() => {
                      handleManageEnvironmentAction(item._id);
                    }}
                  >
                    Ambiente
                  </button>
                </td>
                <td>
                  <button
                    className="edit--button"
                    onClick={() => {
                      handleEditAction(item._id);
                    }}
                  >
                    Editar
                  </button>
                  <button
                    className="delete--button"
                    onClick={() => {
                      handleInactivateItem(item._id);
                    }}
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveTable;
