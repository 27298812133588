import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { FormArea } from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import { isEmpty } from "lodash";

import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import LengthCounter from "../components/LengthCounter";

const Edit = () => {
  const history = useHistory();
  const { action, typeId } = useParams();

  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [status, setStatus] = useState(1);
  const [allowTimeline, setAllowTimeline] = useState(true);
  const [allowVideo, setAllowVideo] = useState(true);
  const [allowImage, setAllowImage] = useState(true);
  const [allowPrivateSpace, setAllowPrivateSpace] = useState(true);
  const [allowCatalog, setAllowCatalog] = useState(true);
  const [allowExternalLink, setAllowExternalLink] = useState(true);
  const [limitImage, setLimitImage] = useState("");
  const [limitVideo, setLimitVideo] = useState("");
  const [limitPrivateSpace, setLimitPrivateSpace] = useState("");
  const [limitAd, setLimitAd] = useState("");
  const [limitCatalogGroup, setLimitCatalogGroup] = useState("");
  const [limitStoreType, setLimitStoreType] = useState("");
  const [limitEnvironment, setLimitEnvironment] = useState("");
  const [errors, setErrors] = useState({
    description: "",
    status: "",
    summary: "",
    allowTimeline: "",
    allowVideo: "",
    allowImage: "",
    allowPrivateSpace: "",
    allowCatalog: "",
    allowExternalLink: "",
    limitImage: "",
    limitVideo: "",
    limitPrivateSpace: "",
    limitAd: "",
    limitCatalogGroup: "",
    limitStoreType: "",
    limitEnvironment: "",
    parent_id: "",
  });

  useEffect(() => {
    setErrors({ ...errors, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setErrors({ ...errors, status: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setErrors({ ...errors, summary: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    setErrors({ ...errors, allowTimeline: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowTimeline]);

  useEffect(() => {
    setErrors({ ...errors, allowVideo: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowVideo]);

  useEffect(() => {
    setErrors({ ...errors, allowImage: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowImage]);

  useEffect(() => {
    setErrors({ ...errors, allowPrivateSpace: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowPrivateSpace]);

  useEffect(() => {
    setErrors({ ...errors, allowCatalog: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCatalog]);

  useEffect(() => {
    setErrors({ ...errors, allowExternalLink: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowExternalLink]);

  useEffect(() => {
    setErrors({ ...errors, limitImage: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitImage]);

  useEffect(() => {
    setErrors({ ...errors, limitVideo: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitVideo]);

  useEffect(() => {
    setErrors({ ...errors, limitPrivateSpace: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitPrivateSpace]);

  useEffect(() => {
    setErrors({ ...errors, limitAd: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitAd]);

  useEffect(() => {
    setErrors({ ...errors, limitCatalogGroup: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitCatalogGroup]);

  useEffect(() => {
    setErrors({ ...errors, limitStoreType: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitStoreType]);

  useEffect(() => {
    setErrors({ ...errors, limitEnvironment: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitEnvironment]);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !typeId) ||
      (action === "create" && typeId)
    ) {
      history.push("/type-store");
      return null;
    }

    if (action === "edit") {
      getTypeId(typeId);
    }
  }, [action, typeId, history]);

  const getTypeId = async (id) => {
    try {
      const response = await Api.getTypeOfStoreById(id);

      if (response.status === 200) {
        const {
          description,
          status,
          summary,
          allowTimeline,
          allowVideo,
          allowImage,
          allowPrivateSpace,
          allowCatalog,
          allowExternalLink,
          limitImage,
          limitVideo,
          limitPrivateSpace,
          limitAd,
          limitCatalogGroup,
          limitStoreType,
          limitEnvironment,
        } = response.data.body.data;

        setDescription(description);
        setStatus(status);
        setSummary(summary);

        /* eslint-disable no-unused-expressions */
        setAllowVideo(allowVideo);
        setAllowTimeline(allowTimeline);
        setAllowImage(allowImage);
        setAllowPrivateSpace(allowPrivateSpace);
        setAllowCatalog(allowCatalog);
        setAllowExternalLink(allowExternalLink);
        setLimitImage(limitImage);
        setLimitVideo(limitVideo);
        setLimitPrivateSpace(limitPrivateSpace);
        setLimitAd(limitAd);
        setLimitCatalogGroup(limitCatalogGroup);
        setLimitStoreType(limitStoreType);
        setLimitEnvironment(limitEnvironment);
      }
    } catch (error) {
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const validateForm = () => {
    const err = {};

    if (description < 3)
      err.description = "O campo deve possuir pelo menos 3 dígitos";

    if (!isEmpty(err)) {
      setErrors({ ...errors, ...err });
      return false;
    }

    setErrors({
      description: "",
      status: "",
      summary: "",
      allowTimeline: "",
      allowVideo: "",
      allowImage: "",
      allowPrivateSpace: "",
      allowCatalog: "",
      allowExternalLink: "",
      limitImage: "",
      limitVideo: "",
      limitPrivateSpace: "",
      limitAd: "",
      limitCatalogGroup: "",
      limitStoreType: "",
      limitEnvironment: "",
    });
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      status: Number.parseInt(status),
      description,
      summary,
      allowTimeline,
      allowVideo,
      allowImage,
      allowPrivateSpace,
      allowCatalog,
      allowExternalLink,
      limitImage: Number.parseInt(limitImage),
      limitVideo: Number.parseInt(limitVideo),
      limitPrivateSpace: Number.parseInt(limitPrivateSpace),
      limitAd: Number.parseInt(limitAd),
      limitCatalogGroup: Number.parseInt(limitCatalogGroup),
      limitStoreType: Number.parseInt(limitStoreType),
      limitEnvironment: Number.parseInt(limitEnvironment),
    };

    console.log("requestBody", requestBody);

    try {
      if (action === "edit") {
        await API.updateTypeOfStore(typeId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Tipo de Loja foi atualizado com sucesso!",
          icon: "success",
        });
      } else {
        await Api.createTypeOfStore(requestBody);

        await swal({
          title: "Tudo certo!",
          text: "Tipo de Loja foi criado com sucesso!",
          icon: "success",
        });
      }

      history.push("/type-store");
    } catch (error) {
      console.log("error", JSON.stringify(error, null, 2));
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/type-store">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>
            {action === "edit"
              ? "Editar Tipo de Loja"
              : "Adicionar Tipo de Loja"}
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="description">Descrição:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required={true}
            maxLength="50"
          />
          <LengthCounter
            props={{
              field: description,
              maxLength: 50,
            }}
          />
          {errors.description !== "" && (
            <div className="form--error">{errors.description}</div>
          )}

          {/* falta exibição de erro */}
          <label htmlFor="resumo">Resumo:</label>
          <textarea
            id="resumo"
            value={summary}
            onChange={(e) => {
              setSummary(e.target.value);
            }}
            required={true}
            maxLength="300"
          />
          <LengthCounter
            props={{
              field: summary,
              maxLength: 300,
            }}
          />

          <label htmlFor="type">
            Permite visualização de time line da loja:
          </label>
          <select
            id="type"
            value={allowTimeline}
            onChange={(e) => setAllowTimeline(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowTimeline !== "" && (
            <div className="form--error">{errors.allowTimeline}</div>
          )}

          <label htmlFor="type">Permite vinculo de vídeos:</label>
          <select
            id="type"
            value={allowVideo}
            onChange={(e) => setAllowVideo(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowVideo !== "" && (
            <div className="form--error">{errors.allowVideo}</div>
          )}

          <label htmlFor="type">Permite vinculo de imagens:</label>
          <select
            id="type"
            value={allowImage}
            onChange={(e) => setAllowImage(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowImage !== "" && (
            <div className="form--error">{errors.allowImage}</div>
          )}

          <label htmlFor="type">Permite criação de espaços privativos:</label>
          <select
            id="type"
            value={allowPrivateSpace}
            onChange={(e) => setAllowPrivateSpace(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowPrivateSpace !== "" && (
            <div className="form--error">{errors.allowPrivateSpace}</div>
          )}

          <label htmlFor="type">Permite criação de catalogo da loja:</label>
          <select
            id="type"
            value={allowCatalog}
            onChange={(e) => setAllowCatalog(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowCatalog !== "" && (
            <div className="form--error">{errors.allowCatalog}</div>
          )}

          <label htmlFor="type">
            Permite geração de link de acesso externo:
          </label>
          <select
            id="type"
            value={allowExternalLink}
            onChange={(e) => setAllowExternalLink(e.target.value === "true")}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
          {errors.allowExternalLink !== "" && (
            <div className="form--error">{errors.allowExternalLink}</div>
          )}

          <label htmlFor="number">Limite de números de imagens:</label>
          <input
            type="number"
            id="limite"
            value={limitImage}
            onChange={(e) => {
              setLimitImage(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitImage !== "" && (
            <div className="form--error">{errors.limitImage}</div>
          )}

          <label htmlFor="number">Limite de número de vídeos:</label>
          <input
            type="number"
            id="limite"
            value={limitVideo}
            onChange={(e) => {
              setLimitVideo(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitVideo !== "" && (
            <div className="form--error">{errors.limitVideo}</div>
          )}

          <label htmlFor="number">
            Limite de número de espaços privativos por loja:
          </label>
          <input
            type="number"
            id="limite"
            value={limitPrivateSpace}
            onChange={(e) => {
              setLimitPrivateSpace(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitPrivateSpace !== "" && (
            <div className="form--error">{errors.limitPrivateSpace}</div>
          )}

          <label htmlFor="number">
            Limite de número de anúncios vinculados:
          </label>
          <input
            type="number"
            id="limite"
            value={limitAd}
            onChange={(e) => {
              setLimitAd(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitAd !== "" && (
            <div className="form--error">{errors.limitAd}</div>
          )}

          <label htmlFor="number">
            Limite de número de grupos de catalogo:
          </label>
          <input
            type="number"
            id="limite"
            value={limitCatalogGroup}
            onChange={(e) => {
              setLimitCatalogGroup(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitCatalogGroup !== "" && (
            <div className="form--error">{errors.limitCatalogGroup}</div>
          )}

          <label htmlFor="number">
            Limite de número de lojas por tipo de loja:
          </label>
          <input
            type="number"
            id="limite"
            value={limitStoreType}
            onChange={(e) => {
              setLimitStoreType(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitStoreType !== "" && (
            <div className="form--error">{errors.limitStoreType}</div>
          )}

          <label htmlFor="number">
            Limite de número de ambientes vinculados:
          </label>
          <input
            type="number"
            id="limite"
            value={limitEnvironment}
            onChange={(e) => {
              setLimitEnvironment(e.target.value);
            }}
            required={true}
            maxLength="6"
          />
          {errors.limitEnvironment !== "" && (
            <div className="form--error">{errors.limitEnvironment}</div>
          )}

          <button type="submit">Salvar</button>
        </form>
      </div>
    </FormArea>
  );
};
export default Edit;
