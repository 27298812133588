import { PageArea } from "./styled";
import API from '../../../utils/api';
import { useState } from "react";
import { downloadCsv } from "../../../utils/generate.csv";
import ExportsCardComponent from '../components/exportsCard';
import { formatDate, formatTime } from "../../../utils/date";

const ExportsPage = () => {
    const [ loading1, setLoading1 ] = useState(false);
    const [ loading2, setLoading2 ] = useState(false);
    const [ loading3, setLoading3 ] = useState(false);
    const [ loading4, setLoading4 ] = useState(false);
    let ordersCsvData = [];
    let ordersItemsCsvData = [];
    let refundCsvData = [];
    let usersCsvData = [];

    const getOrdersInfoAndDownloadCsv = async () => {
        setLoading1(true);
        await getOrdersInfo();
        downloadCsv(ordersCsvData, 'pedidos', true);
        setLoading1(false);
    };

    const getOrdersInfo = async () => {
        const response = await API.getOrders();
        if (response.status !== 200) {
            return;
        }
        const responseData = response.data.body.data;
        const formattedData = responseData.map((order) => {
            return {
                "loja_id": order.storeId._id,
                "LojaTitulo": order.storeId.title,
                "vendedor_ddd": order.storeId.responsible.accountId.dddPhone,
                "vendedor_celular": order.storeId.responsible.accountId.cellPhone,
                "RazaoSocial": order.profile.corporateName ?? "",
                "Responsavel": order.profile.fullName ?? "",
                "email": order.buyer.email,
                "tipo": order.profile.type,
                "pedido_id": order._id,
                "pedidoStatus": convertOrderStatus(order.status),
                "PedidoNumero": order.orderNumber,
                "dataRegistro": formatDate(order.createdAt),
                "horaRegistro": formatTime(order.createdAt),
                "totalPedido": order.totalPrice && order.promotionId ? (order.totalPrice + order.promotionId.discountValue) / 100 : 0,
                "consumidor_id": order.buyer._id,
                "nomeConsumidor": order.buyer.accountName,
                "consumidor_ddd": order.buyer.dddPhone,
                "consumidor_celular": order.buyer.cellPhone,
                "formaPgto_id": order.paymentMethodId._id,
                "formaPgto": order.paymentMethodId.description
            };
        });
        ordersCsvData = formattedData;
    };

    const getOrderItemsInfoAndDownloadCsv = async () => {
        setLoading2(true);
        await getOrderItemsInfo();
        downloadCsv(ordersItemsCsvData, 'pedidosItens', true);
        setLoading2(false);
    };

    const getOrderItemsInfo = async () => {
        const response = await API.getSalesOrderItems();
        if (response.status !== 200) {
            return;
        }

        const responseData = response.data.body.data;

        const formattedData = responseData.filter((order) => {
            if (!order.salesOrderId) {
                return false;
            }
            return true;
        }).map((orderItem) => {
            return {
                "pedido_id": orderItem.salesOrderId._id,
                "pedidoNumero": orderItem.salesOrderId.orderNumber,
                "pedidoStatus": convertOrderStatus(orderItem.salesOrderId.status),
                "dataRegistro": formatDate(orderItem.createdAt),
                "loja_id": orderItem.salesOrderId.storeId,
                "anuncio_id": orderItem.announceId._id,
                "anuncio": orderItem.announceId.title,
                "valor (R$)": orderItem.value / 100,
                "qtde": orderItem.amount,
            };
        });

        ordersItemsCsvData = formattedData;
    };

    const getRefundInfoAndDownloadCsv = async () => {
        setLoading3(true);
        await getRefundInfo();
        if (refundCsvData.length !== 0) {
            downloadCsv(refundCsvData, 'reembolso', true);
        }
        setLoading3(false);
    };

    const getRefundInfo = async () => {
        const response = await API.getOrders();
        if (response.status !== 200) {
            return;
        }
        const responseData = response.data.body.data;

        const formatedData = responseData.filter((order) => {
            if (!order.promotionId) {
                return false;
            }
            return true;
        }).map((order) => {
            return {
                "loja_id": order.storeId._id,
                "LojaTitulo": order.storeId.title,
                "RazaoSocial": order.profile.corporateName ?? "",
                "Responsavel": order.profile.fullName ?? "",
                "ddd": order.buyer.dddPhone,
                "celular": order.buyer.cellPhone,
                "email": order.buyer.email,
                "tipo": order.profile.type,
                "pedido_id": order._id,
                "pedidoStatus": convertOrderStatus(order.status),
                "PedidoNumero": order.orderNumber,
                "dataRegistro": formatDate(order.createdAt),
                "horaRegistro": formatTime(order.createdAt),
                "totalPedido": (order.totalPrice + order.promotionId.discountValue) / 100,
                "promocao_id": order.promotionId._id,
                "promocao": order.promotionId.description,
                "valor reembolso (R$)": order.promotionId.discountValue / 100,
                "valor final pago": order.totalPrice / 100,
                "consumidor_id": order.buyer._id,
                "nomeConsumidor": order.buyer.accountName,
            };
        });

        refundCsvData = formatedData;
    };

    const getUsersInfoAndDownloadCsv = async () => {
        setLoading4(true);
        await getUsersInfo();
        downloadCsv(usersCsvData, 'consumidores', true);
        setLoading4(false);
    };

    const getUsersInfo = async () => {
        const response = await API.getAccountProfiles();

        if (response.status !== 200) {
            return;
        }
        const responseData = response.data.body.data;
        console.log(responseData);
        const formattedData = responseData.filter((profile) => {
            if (!profile.accountId) {
                return false;
            }
            return true;
        })
            .map((profile) => {
                return {
                    "conta_id": profile.accountId._id,
                    "nome": profile.accountId.accountName,
                    "dddPhone": profile.accountId.dddPhone,
                    "cellPhone": profile.accountId.cellPhone,
                    "email": profile.accountId.email,
                    "criadoEm": formatDate(profile.accountId.createdAt),
                    "perfil_id": profile._id,
                    "tipo": profile.type,
                    "nomeCompleto": profile.fullName ?? "",
                    "dataNascimento": profile.birthdayOrOpening ? formatDate(profile.birthdayOrOpening) : '',
                    "razaoSocial": profile.corporateName ?? '',
                    "nomeFantasia": profile.fantasyName ?? '',
                    "possuiLoja": profile.Store.length !== 0 ? "SIM" : "NAO",
                    "loja_id": profile.Store.length !== 0 ? profile.Store[ 0 ]._id : "",
                    "status_loja": profile.Store.length !== 0 ? profile.Store[ 0 ].status : "",
                    "lojaTitulo": profile.Store.length !== 0 ? profile.Store[ 0 ].title : "",
                };
            });
        usersCsvData = formattedData;
    };

    const convertOrderStatus = (status) => {
        switch (status) {
            case (1):
                return 'Encaminhado';
            case (2):
                return 'Em Preparo';
            case (3):
                return 'Preparado';
            case (4):
                return 'Concluído';
            case (5):
                return 'Avaliado';
            case (6):
                return 'Devolvido';
            case (7):
                return 'Cancelado';
            case (8):
                return 'Recusado';
            default:
                return '';
        }
    };

    return <PageArea>
        <div className="page-area">
            <div className="page-label">
                <h1 >Exportadores</h1>

            </div>
            <div className="content-area">
                <ExportsCardComponent
                    label="Informações de Pedidos"
                    onclick={ () => getOrdersInfoAndDownloadCsv() }
                    loading={ loading1 }
                />
                <ExportsCardComponent
                    label="Informações de Itens de Pedidos"
                    onclick={ () => getOrderItemsInfoAndDownloadCsv() }
                    loading={ loading2 }
                />

                <ExportsCardComponent
                    label="Informações de Reembolso"
                    onclick={ () => getRefundInfoAndDownloadCsv() }
                    loading={ loading3 }
                />

                <ExportsCardComponent
                    label="Informações de Consumidores"
                    onclick={ () => getUsersInfoAndDownloadCsv() }
                    loading={ loading4 }
                />
            </div>
        </div>
    </PageArea>;
};

export default ExportsPage;