import React, {useEffect, useRef, useState} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {FormArea} from "./styled";
import Api from "../../utils/api";
import swal from "sweetalert";
import backButton from "../../assets/arrow_back.svg";
import API from "../../utils/api";
import LengthCounter from "../components/LengthCounter";
import {SizedBox} from "../../components/SizedBox";
import AsyncSelect from "react-select/async";

const Edit = () => {
  const history = useHistory();
  const {action, bannerId} = useParams();
  const imageInput = useRef(null);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [resume, setResume] = useState("");
  const [origin, setOrigin] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [destinationURL, setDestinationURL] = useState("");
  const [storeId, setStoreId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const availableActions = ["edit", "create"];

    if (
      !availableActions.includes(action) ||
      (action === "edit" && !bannerId) ||
      (action === "create" && bannerId)
    ) {
      history.push("/banners");
      return null;
    }

    if (action === "edit") {
      // getGroupId(groupId);
    }
  }, [action, bannerId, history]);

  useEffect(() => {
    const getBanners = async () => {
      if (!bannerId) return;
      const response = await API.getBannerById(bannerId);
      const data = response.data.body.data;

      setTitle(data.title ?? "");
      setType(String(data.type) ?? "");
      setResume(data.resume ?? "");
      setOrigin(String(data.origin) ?? "");
      setDestinationURL(data.destinationURL ?? "");
      setStoreId(data.storeId ?? "");
    };

    getBanners();
  }, [bannerId]);

  // useEffect(() => {
  //   const getStore = async () => {
  //     const response = await API.getStoreFilter({skip: 0, limit: 10, title: storeTitle});
  //     // setStore(response.data.body.data);
  //   };

  //   if (origin === "2") {
  //     getStore();
  //   }
  // }, [origin]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      title,
      type: parseInt(type),
      resume,
      destinationURL,
      origin: parseInt(origin),
      storeId,
    };

    if (origin === "1") {
      requestBody.storeId = "";
    }

    let bannerID;
    try {
      if (action === "edit") {
        setLoading(true);
        if (imageUrl) await Api.uploadImageBanner(bannerId, imageUrl);
        await API.updateBanner(bannerId, requestBody);

        await swal({
          title: "Tudo certo!",
          text: "O banner foi atualizado com sucesso!",
          icon: "success",
        });
        setLoading(false);
      } else {
        setLoading(true);
        const response = await Api.createBanner(requestBody);
        bannerID = response.data.body.data._id;

        if (imageUrl) await Api.uploadImageBanner(bannerID, imageUrl);

        await swal({
          title: "Tudo certo!",
          text: "O banner foi criado com sucesso!",
          icon: "success",
        });
        setLoading(false);
      }

      history.push("/banners");
    } catch (error) {
      setLoading(false);
      if (
        error.response.data.body.errors === "exceptions:UNSUPPORTED_FILE_TYPE"
      ) {
        await Api.deleteBannerById(bannerID);

        await swal({
          title: "Formato inválido",
          text: "O formado da imagem não é suportado. Por favor, tente outra imagem.",
          icon: "error",
        });

        return;
      }
      await Api.deleteBannerById(bannerID);
      await swal({
        title: "Algo inesperado aconteceu!",
        text: "Por favor tente novamente mais tarde.",
        icon: "error",
      });
    }
  };

  const handleUploadFile = async (input) => {
    const imageUrl = input.target.files[0];
    const data = new FormData();
    data.append("file", imageUrl);
    setImageUrl(data);
    setLoading(false);
  };

  const loadOptions = async (title, callback) => {
    const response = await API.getStoreFilter({
      skip: 0,
      limit: 10,
      title,
    });
    const arr = response?.data?.body?.data;

    if (!arr) {
      callback([]);
      return;
    }

    callback(
      arr.map((i) => ({
        label: i.title,
        value: i.title,
        id: i._id,
      }))
    );
  };

  // handle selection
  const handleChange = (value) => {
    if (!value?.id) return;
    setStoreId(value.id);
  };

  return (
    <FormArea>
      <div className="form--area">
        <div className="form--header">
          <Link to="/banners">
            <img src={backButton} alt="Voltar" />
          </Link>

          <h1>{action === "edit" ? "Editar Banner" : "Adicionar Banner"}</h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label className="form-label">Imagem</label>
          <input
            ref={imageInput}
            type="file"
            required={action === "create"}
            onChange={handleUploadFile}
            accept="image/*"
          />
          <SizedBox height={16} />
          <label>Título</label>
          <input
            type="text"
            id="title"
            value={title}
            maxLength={200}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required={true}
          />
          <LengthCounter
            props={{
              field: title,
              maxLength: 200,
            }}
          />
          <label>Resumo</label>
          <input
            type="text"
            id="resume"
            value={resume}
            maxLength={1000}
            onChange={(e) => {
              setResume(e.target.value);
            }}
            required={true}
          />
          <LengthCounter
            props={{
              field: resume,
              maxLength: 1000,
            }}
          />
          <label>Tipo</label>
          <div>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="">Selecione o tipo do banner</option>
              <option value="1">Estático</option>
              <option value="2">Animado</option>
              <option value="3">Vídeo</option>
              <option value="4">Popup</option>
            </select>
          </div>
          <label>Ação</label>
          <div>
            <select
              value={destinationURL}
              onChange={(e) => setDestinationURL(e.target.value)}
              required
            >
              <option value="">Selecione a o redirecionamento</option>
              <option value="/food-establishment">Loja - Alimentação</option>
              <option value="/products-establishment">Loja - Produtos</option>
              <option value="/services-establishment">Loja - Serviços</option>
              <option value="/properties-establishment">Loja - Imóveis</option>
            </select>
          </div>
          <label>Origem</label>
          <div>
            <select
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
              required
            >
              <option value="">Selecione a origem do banner</option>
              <option value="1">Plataforma</option>
              <option value="2">Estabelecimento</option>
            </select>
          </div>
          {origin === "2" && (
            <>
              <label>Loja</label>
              <AsyncSelect
                isClearable
                placeholder={"Nome da loja"}
                loadOptions={loadOptions}
                onChange={handleChange}
              />
            </>
          )}
          <button type="submit">{!loading ? "Salvar" : "Salvando..."}</button>
        </form>
        <SizedBox height={24} />
      </div>
    </FormArea>
  );
};

export default Edit;
